export function withReset<T extends { _state: Record<string, any> }>(state: T) {
  const _initState: any = {};
  for (const key of Object.keys(state as any)) {
    // Any value that is not a function
    if (typeof (state as any)[key] !== 'function') {
      const item = (state as any)[key];
      _initState[key] = isRef(item) ? item.value : item;
    }
  }
  return {
    ...state,
    _initState,
    $reset() {
      for (const key of Object.keys(this._state)) {
        (this as any)[key] = this._initState[key];
        // (this as any).$patch((state: any) => state[key] = this._initState[key])
      }
    }
  };
}
